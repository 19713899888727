type ReactionBarButtonProps = {
  emoji: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function ReactionBarButton({ emoji, ...rest }: ReactionBarButtonProps) {
  return (
    <div
      className="flex items-center justify-center p-1 rounded-full bg-[var(--bg)] border border-white/20 hover:border-white/30 cursor-pointer active:scale-90 select-none"
      {...rest}>
      <span className="text-2xl">{emoji}</span>
    </div>
  )
}