import React, { useContext } from "react";
import WSContext from "../contexts/wsContext";
import { isGoodWebsocket } from "../util";
import { EmojiDisplayField } from "./EmojiDisplay";
import EmojiEvent from "../types/EmojiEvent";

export function PointerField({ ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  const [wsState, setWsState] = React.useState<WebSocket["readyState"]>(WebSocket.CONNECTING);
  const [emojiEvents, setEmojiEvents] = React.useState<EmojiEvent[]>([]);
  const [positions, setPositions] = React.useState<[number, number][]>([]);
  const fieldRef = React.useRef<HTMLDivElement>(null);
  const lastChange = React.useRef<number>(0);
  const sendTimeout = React.useRef<number>(0);
  const wsStore = useContext(WSContext);

  React.useEffect(() => {
    const connectionString =
      (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        ? `ws${window.location.protocol.slice(4, window.location.protocol.length - 1)}://${window.location.hostname}:8104/ws`
        : `ws${window.location.protocol.slice(4, window.location.protocol.length - 1)}://${window.location.hostname}/ws`;
    const ws = new WebSocket(connectionString);
    wsStore.ws = ws;
    console.info("Connected websocket");

    ws.onopen = () => { setWsState(ws.readyState); }
    ws.onclose = () => { setWsState(ws.readyState); }
    ws.onerror = () => { setWsState(ws.readyState); }

    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.type === "positions") {
        setPositions(data.data);
      } else if (data.type === "emoji") {
        setEmojiEvents((events) => [...events.filter(e => e.timestamp + 5000 > Date.now()), { emoji: data.data, timestamp: Date.now() }]);
      }
    }

    return () => {
      console.info("Closing websocket");
      ws.close();
    }
  }, [wsStore]);

  function handlePosChange(e: React.MouseEvent<HTMLDivElement>) {
    if (!fieldRef) {
      return;
    }

    const rect = fieldRef.current!.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width * 100;
    const y = (e.clientY - rect.top) / rect.height * 100;

    if (isGoodWebsocket(wsStore.ws) && Date.now() - lastChange.current > 100) {
      window.clearTimeout(sendTimeout.current);
      wsStore.ws!.send(JSON.stringify({ type: "position", data: [x, y] }));
      lastChange.current = Date.now();
    }
  }

  return (
    <div
      ref={fieldRef}
      className="relative bg-[var(--bg)] w-full grow box-border flex items-center justify-center text-white/50 rounded-3xl overflow-hidden border border-white/20 hover:border-white/30 select-none text-center p-8"
      onMouseMove={handlePosChange}
      {...rest}>
      {wsState === WebSocket.OPEN ? "Move your cursor baybee" : wsState === WebSocket.CONNECTING ? "Connecting....." : "Disconnected from websocket.\nRefresh the page to reconnect."}
      <div className="absolute w-full h-full overflow-hidden">
        {positions.map(([x, y], i) => (
          <div
            key={i}
            className="absolute w-2 h-2 rounded-full duration-100 ease-linear -translate-x-1/2 -translate-y-1/2"
            style={{ left: `${x}%`, top: `${y}%`, backgroundColor: `hsl(${i * 42}, 100%, 50%)` }}
          />
        ))}
      </div>
      <EmojiDisplayField events={emojiEvents} />
    </div>
  );
}