import { Columnize } from "./components/Columnize";
import { PointerField } from "./components/PointerField";
import { ReactionBar } from "./components/ReactionBar";

function App() {
  return (
    <Columnize>
      <div className="flex flex-col h-3/4 max-sm:h-full w-full gap-5">
        <header className="p-5 pb-0">
          <h1 className="text-center font-bold text-3xl">Interactive hype page demo</h1>
        </header>
        <PointerField />
        <ReactionBar />
      </div>
    </Columnize>
  );
}

export default App;
