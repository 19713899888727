// WSContext stores the websocket connection and the current user

import React from 'react';

export interface WSContextType {
  ws: WebSocket | null;
}

const WSContext = React.createContext<WSContextType>({
  ws: null,
});

export default WSContext;