import React from "react";
import WSContext from "../contexts/wsContext";
import { ReactionBarButton } from "./ReactionBarButton";
import { isGoodWebsocket } from "../util";

export function ReactionBar() {
  const emojis = ["⌨️", "🔥", "🍔", "🎊", "🍰"];
  const wsStore = React.useContext(WSContext);

  function handleClick(e: string) {
    if (!isGoodWebsocket(wsStore.ws)) {
      return;
    }

    wsStore.ws!.send(JSON.stringify({ type: "emoji", data: e }));
  }

  return <div className="w-full box-border p-5 bg-[var(--bg)] border border-white/20 hover:border-white/30 rounded-3xl flex flex-wrap justify-center gap-2">
    {emojis.map((emoji, i) => <ReactionBarButton key={i} emoji={emoji} onClick={() => handleClick(emoji)} />)}
  </div>
}