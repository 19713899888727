import React from "react";
import EmojiEvent from "../types/EmojiEvent"

export type EmojiDisplayFieldProps = {
  events: EmojiEvent[];
} & React.HTMLAttributes<HTMLDivElement>;

export function EmojiDisplayField({ events, ...rest }: EmojiDisplayFieldProps) {
  return <div className="absolute w-full h-full overflow-hidden" {...rest}>
    {events.filter(e => e.timestamp + 5000 > Date.now()).map((event) => <EmojiDisplay key={event.timestamp} event={event} />)}
  </div>
}

export function EmojiDisplay({ event }: { event: EmojiEvent }) {
  const { emoji } = event;
  const x = event.timestamp % 100;
  const y = Math.floor(event.timestamp * 4.5) % 100;

  const [opacity, setOpacity] = React.useState(1);

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      const ago = Date.now() - event.timestamp;
      const len = 5000;

      setOpacity(ago < len ? 1 - ago / len : 0);

      if (ago > len) {
        window.clearInterval(interval);
      }
    }, 100);

    return () => {
      window.clearInterval(interval);
    }
  }, [event.timestamp]);

  return <div
    className="absolute w-10 h-10 rounded-full duration-100 ease-linear -translate-x-1/2 -translate-y-1/2 flex justify-center items-center"
    // style={{ left: `${x}%`, top: `${y}%`, backgroundColor: `hsl(${x * 42}, 100%, 50%)`, opacity }}
    style={{ left: `${x}%`, top: `${y - 10 + opacity * 10}%`, opacity }}
  >
    <span className="text-2xl lg:text-4xl text-white">{emoji}</span>
  </div>;
}